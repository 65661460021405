<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_passenger')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.fullName"
                                    :label="$t('full_name')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>

                       <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.dateOfBirth"
                                            :label="$t('dob')"
                                            clearable
                                            prepend-inner-icon="event"
                                            readonly
                                                  dense
                                  outlined
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.nationality"
                                    :label="$t('nationality')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.country"
                                    :items="countries"
                                    :label="$t('country')"
                                            dense
                                  outlined
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.state"
                                    :items="state"
                                    label="State"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->


                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.airName"
                                    :items="AviationList"
                                    :label="$t('air_name')"
                                    clearable
                                            dense
                                  outlined
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="changeFlightNumber"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.flightNumber"
                                    :items="AviationDetailList"
                                    :label="$t('flight_number')"
                                    clearable
                                    single-line
                                            dense
                                  outlined
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.ticketNumber"
                                    :label="$t('ticket_number')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                                            label="Passenger Type"></v-autocomplete>
                        </v-col>-->
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.serviceID"
                                    :items="Services"
                                    chips
                                    deletable-chips
                                    :label="$t('services')"
                                    clearable
                                            dense
                                  outlined
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.packageID"
                                    :items="AirPackages"
                                    chips
                                    deletable-chips
                                    :label="$t('package')"
                                    clearable
                                            dense
                                  outlined
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.softwareUsed"
                                    :items="SoftwareUsedList"
                                    :label="$t('software_used')"
                                            dense
                                  outlined
                                    clearable
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>


                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.paymentType"
                                    :items="PaymentType"
                                            dense
                                  outlined
                                    :label="$t('payment_type')"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.flightDate"
                                            :label="$t('flight_date')"
                                            clearable
                                            prepend-inner-icon="event"
                                            readonly
                                                  dense
                                  outlined
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.flightDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.citizenShipNumber"
                                    :label="$t('citizenshipNumber')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.passportNumber"
                                    :label="$t('passport_number')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.licenseNumber"
                                    :label="$t('licenseNumber')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.otherID"
                                    :label="$t('otherID')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.document"
                                    :label="$t('documents')"
                                    required
                                          dense
                                  outlined
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    v-model="customerData.isVerifiedDocument"
                                    :label="$t('is_verified_document')"
                                      dense
                                  outlined
                                    clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.issuedBy"
                                    :label="$t('issued_by')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.issuedDate"
                                            :label="$t('issued_date')"
                                            clearable
                                                  dense
                                  outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "TravelTourCustomerEditForm",
        data() {
            return {
                AirPackages: [],
                customerData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                SoftwareUsedList: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: ['a'],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                },
                AviationDetailList: [],
                AviationList: [],
                PaymentType: ["Cash", 'Account Deposite', 'Credit Card']
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            formSubmit() {
                this.customerData.age = parseInt(this.customerData.age)
                this.$delete(this.customerData, 'createdBy')
                this.customerData.issuedDate = this.customerData.issuedDate == "" ? null : this.customerData.issuedDate
                axios.post('TravelTour/UpdateCustomerInformationAsync', this.customerData)
                    .then(response => {
                        if (response.data.success) {

                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer updated succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    })
            },
            async changeFlightNumber(value) {
                let FlightNumber = await axios.get(`Aviation/DDLAviationDetailListAsync/${value}`)
                this.AviationDetailList = FlightNumber.data
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                const countryList = await axios.get("Shared/CountryList");
                this.countries = countryList.data;
                const Services = await axios.get("TravelTour/DDLTravelTourServiceListAsync");
                this.Services = Services.data
                const Package = await axios.get('TravelTour/DDLTravelTourPackageListAsync');
                this.AirPackages = Package.data
                const Software = await axios.get('Shared/GetTravelTourSoftwareUsedList');
                this.SoftwareUsedList = Software.data
                const AviationData = await axios.get('Aviation/DDLAviationListAsync');
                this.AviationList = AviationData.data

            },
            async getEditData() {
                const CustomerData = await axios.get('TravelTour/GetCustomerInformationByIDAsync/' + this.editItemIndex)
                this.customerData = CustomerData.data
                this.changeFlightNumber(CustomerData.data.airName)
                this.customerData.flightNumber = this.customerData.data.flightNumber
            }
        },
        mounted() {
            this.formData();
            this.getEditData()
        },
    }
</script>

<style scoped>

</style>