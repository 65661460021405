<template>
    <div class="container">
        <v-breadcrumbs :items="breadcrumbsItem" large></v-breadcrumbs>

        <v-snackbar v-model="snackbarc" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-container>
            <v-row class="float-right">
                <v-btn @click.stop="onDownload" class="success float-right mx-4">
                    <v-icon>get_app</v-icon>
                    {{$t('download_sample')}}
                </v-btn>


                <v-btn @click="importExportModal" class="warning float-right mx-4">

                    {{$t('import')}}
                </v-btn>
                <v-dialog width="50%"
                          persistent
                          transition="dialog-bottom-transition"
                          v-model="snackbar.importExportComponent">

                    <ImportExportModal
                            :ieData="ieData"
                            @importExportModalResponse="importExportModalResponse"
                            v-if="snackbar.importExportComponent"
                    ></ImportExportModal>

                </v-dialog>
            </v-row>
        </v-container>
        <div slot="table-actions">
            <v-btn color="primary" @click.stop="addModal">{{$t('passenger_form')}}</v-btn>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
        </v-dialog>
        <vue-good-table :columns="columns"
                        :rows="rows"
                        mode="remote"
                        :totalRows="customerData.totalRecords"
                        :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange"
                        styleClass="vgt-table condensed">
            <template slot="table-column" slot-scope="props">
                <span>
                    {{ $t(props.column.label) }}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'issuedDate'">
                    <span>{{props.row.issuedDate | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                </span>
                <span v-else-if="props.column.field == 'actions'">
                    <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                    <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                </span>
            </template>
        </vue-good-table>
        <v-dialog v-model="snackbar.formModal"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  persistent>
            <v-card>
                <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="customerData.updateItem"
             ></component>-->
                <CustomerForm @formResponse="formResponse"
                              v-if="snackbar.createFormComponent"></CustomerForm>
                <CustomerEditForm @formResponse="formResponse"
                                  v-if="snackbar.editFormComponent"
                                  :editItemIndex="customerData.editItemIndex"></CustomerEditForm>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import CustomerForm from "./CustomerForm";
    import CustomerEditForm from "./CustomerEditForm";
    import deleteListData from "@/components/deleteModal";
    import ImportExportModal from "@/components/ImportExport/ImportExportModal"

    export default {
        name: "TravelTourCustomerList",
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData,
            ImportExportModal
        },
        methods: {

            importExportModal(){
                this.snackbar.importExportComponent = true
            },
            importExportModalResponse(data){
                this.snackbar.importExportComponent = false
                if(data.modal){
                    this.snackbar.snackbar = true
                    this.callbackResponse.message = data.message
                    this.loadItems();
                }
            },

            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("TravelTour/Import", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            async onDownload() {
                await axios({
                    url: 'TravelTour/Download',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportTravelTourFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                if (data.message) {
                    this.callbackResponse = data;
                    this.snackbarc = true
                    this.dialogDelete = false
                    this.loadItems();
                    this.customerData.editItemIndex = null
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false
                    this.snackbar.editFormComponent = false
                } else {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false
                    this.snackbar.editFormComponent = false
                    this.dialogDelete = false
                }
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber,
                    FromDate: null,
                    ToDate: null
                };
                axios.post('TravelTour/GetCustomerInformationListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "TravelTour/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.loadItems()
        },
        computed: {
            breadcrumbsItem() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'TravelTourDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('travel_and_tour'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                ieData:{
                    import:"TravelTour/Import",
                },
                snackbarc: false,
                totalRecords: 0,
                callbackResponse: {
                    timeout: 6000
                },
                columns: [

                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'nationality',
                        field: 'nationality',
                    },
                    {
                        label: 'ticket_no',
                        field: 'ticketNumber',
                    },
                    {
                        label: 'issued_date',
                        field: 'issueDate',
                    },
                    {label: "actions", field: "actions"}
                ],
                rows: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "PersonInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    importExportComponent: false,
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
