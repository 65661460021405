<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_passenger')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>

                    <v-card class="my-12">
                        <v-toolbar class="darken-1" color="blue" dark fixed>
                            <v-toolbar-title>
                                {{$t('find_person_id')}}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                            :items="['Passport','Citizen']"
                                            :label="$t('type')"
                                            v-model="trackType"
                                              dense
                                  outlined
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            :label="$t('enter_your_id')"
                                            v-model="trackID"
                                                  dense
                                  outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="12">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="getUserData" class="mx-2" color="primary" dark small>
                                        <v-icon dark> search</v-icon>
                                        {{$t('search')}}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.fullName"
                                    :label="$t('full_name')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.dateOfBirth"
                                            :label="$t('dob')"
                                            clearable
                                                  dense
                                  outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.nationality"
                                    :label="$t('nationality')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.country"
                                    :items="countries"
                                    :label="$t('country')"
                                    clearable
                                            dense
                                  outlined
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.state"
                                    :items="state"
                                    label="State"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.airName"
                                    :items="AviationList"
                                    :label="$t('air_name')"
                                    clearable
                                    single-line
                                            dense
                                  outlined
                                    item-text="value"
                                    item-value="id"
                                    @change="changeFlightNumber"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.flightNumber"
                                    :items="AviationDetailList"
                                    :label="$t('flight_number')"
                                    clearable
                                            dense
                                  outlined
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.ticketNumber"
                                    :label="$t('ticket_number')"
                                    required
                                          dense
                                  outlined
                            ></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                                            label="Passenger Type"></v-autocomplete>
                        </v-col>-->
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.serviceID"
                                    :items="Services"
                                    chips
                                    deletable-chips
                                    :label="$t('services')"
                                    clearable
                                    dense
                                  outlined
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.packageID"
                                    :items="AirPackages"
                                    chips
                                    deletable-chips
                                    :label="$t('package')"
                                    clearable
                                            dense
                                  outlined
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.softwareUsed"
                                    :items="SoftwareUsedList"
                                    :label="$t('software_used')"
                                    clearable
                                            dense
                                  outlined
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.paymentType"
                                    :items="PaymentType"
                                    :label="$t('payment_type')"
                                            dense
                                  outlined
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.flightDate"
                                            :label="$t('flight_date')"
                                            clearable
                                                  dense
                                  outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.flightDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.citizenShipNumber"
                                    :label="$t('citizenshipNumber')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.passportNumber"
                                    :label="$t('passport_number')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.licenseNumber"
                                    :label="$t('licenseNumber')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.otherID"
                                    :label="$t('otherID')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.document"
                                    :label="$t('documents')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    v-model="customerData.isVerifiedDocument"
                                    :label="$t('is_verified_document')"
                                      dense
                                  outlined
                                    clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.issuedBy"
                                    :label="$t('issued_by')"
                                          dense
                                  outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.issuedDate"
                                            :label="$t('issued_date')"
                                            clearable
                                                  dense
                                  outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        name: "TravelTourCustomerForm",

        watch: {
            "dropdownData.citizenShipNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                            params:{
                                CitizenShipNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            "dropdownData.passportNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByPassportAsync",{
                            params:{
                                passportNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            searchCitizen(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                    axios.get("Track/GetCitizenIDListAsync", {
                        params: {
                            CitizenID: val
                        }
                    })
                        .then(res => {
                            this.citizenshipData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            searchPassport(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    axios.get("Track/GetPassportIDListAsync", {
                        params: {
                            PassportNumber: val
                        }
                    })
                        .then(res => {
                            this.passportData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
        },

        data() {
            return {
                trackID:'',
                trackType:'',
                citizenshipData:[],
                passportData:[],
                dropdownData:{
                    citizenShipNumber:"",
                    passportNumber:""
                },
                isLoading: false,
                searchCitizen: null,
                searchPassport: null,

                customerData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                AirPackages: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: [],
                SoftwareUsedList: [],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                },
                AviationDetailList: [],
                AviationList: [],
                PaymentType: ["Cash", 'Account Deposite', 'Credit Card']

            }
        },
        computed: {},
        methods: {

            getUserData() {
                if (this.trackID) {
                    this.userInfo = []
                    axios.get('Track/GetPersonInformationByID', {
                        params: {
                            ID: this.trackID,
                            Type: this.trackType
                        }
                    }).then(response => {
                        console.log(this.customerData, response.data)
                        this.customerData.fullName = response.data.fullName
                        this.customerData.nationality = response.data.nationality
                        this.customerData.country = response.data.country
                        this.customerData.state = response.data.state
                        this.customerData.gender = response.data.gender
                        this.customerData.age = response.data.age
                        this.customerData.citizenShipNumber = response.data.citizenShipNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.licenseNumber = response.data.licenseNumber
                        this.customerData.dateOfBirth = moment(response.data.dateOfBirth).format('YYYY-MM-DD')

                        if (response.data.country) {
                            this.getState(response.data.country)
                        }
                        this.trackID = ""
                    })
                }
            },

            dialogueClose() {
                this.customerData = {}
                this.customerFormData = []
                this.$emit('formResponse', this.servicesData)
            },
            async changeFlightNumber(value) {
                let FlightNumber = await axios.get(`Aviation/DDLAviationDetailListAsync/${value}`)
                this.AviationDetailList = FlightNumber.data
            },
            formSubmit() {
                this.customerData.age = parseInt(this.customerData.age)
                axios.post('TravelTour/InsertIntoCustomerInformationAsync', this.customerData)
                    .then(response => {
                        if (response.data.success) {
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                const countryList = await axios.get("Shared/CountryList");
                this.countries = countryList.data;
                const Services = await axios.get("TravelTour/DDLTravelTourServiceListAsync");
                this.Services = Services.data
                const Package = await axios.get('TravelTour/DDLTravelTourPackageListAsync');
                this.AirPackages = Package.data

                const Software = await axios.get('Shared/GetTravelTourSoftwareUsedList');
                this.SoftwareUsedList = Software.data

                const AviationData = await axios.get('Aviation/DDLAviationListAsync');
                this.AviationList = AviationData.data
            },
        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>